import { ReactElement } from "react";
import "./Portfolio.scss";
import Group from "../Group/Group";

export default function Portfolio(): ReactElement {
  return (
    <div className="portfolio">
      <Group title="Stocks" type="EQUITY" />
      <Group title="Crypto" type="CRYPTOCURRENCY" />
      <Group title="Funds" type="ETF" />
    </div>
  );
}
