export const initialState = [];

export const currenciesReducer = (
  state: Currency[] = initialState,
  action: any
) => {
  switch (action.type) {
    case "SET_CURRENCIES": {
      return action.payload;
    }
    default:
      return state;
  }
};
