/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useState } from "react";
import Portfolio from "../Portfolio/Portfolio";
import { auth, firestore } from "../../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import "./Dashboard.scss";
import Total from "../Total/Total";
import assetStructure from "../../functions/initialiseAssets";
import { useDispatch } from "react-redux";
import { setAssets } from "../../redux/actions/assetsActions";
import AddAsset from "../AddAsset/AddAsset";
import { setRefs } from "../../functions/setStore";
import historicalNwStructure from "../../functions/initialiseNws";
import { setNws } from "../../redux/actions/historicalNwsActions";
import { setCurrency } from "../../redux/actions/currencyActions";
import currencyStructure from "../../functions/initialiseCurrencies";
import { setCurrencies } from "../../redux/actions/currenciesActions";
import Footer from "../Footer/Footer";

export default function Dashboard(): ReactElement {
  // let history = useHistory();
  const assetsRef = firestore.collection(
    `users/${auth.currentUser!.uid}/assets`
  );
  const historicalNwsRef = firestore
    .collection(`users/${auth.currentUser!.uid}/historicalNWs`)
    .orderBy("createAt");
  const settingsRef = firestore.collection(
    `users/${auth.currentUser!.uid}/settings`
  );
  const currenciesRef = firestore.collection("currencies");
  const [firebaseAssets] = useCollectionData(assetsRef, { idField: "id" });
  const [firebaseHistoricalNws] = useCollectionData(historicalNwsRef, {
    idField: "id",
  });
  const [firebaseSettings] = useCollectionData(settingsRef, { idField: "id" });
  const [firebaseCurrencies] = useCollectionData(currenciesRef, {
    idField: "id",
  });
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const signOut = () => {
    if (window.confirm("Are you sure want sign out?")) {
      auth.signOut();
      // history.push("/");
    }
  };

  useEffect(() => {
    setRefs(dispatch);
  }, []);

  // when currency is updated
  useEffect(() => {
    setLoading(true);
    let currency: string = firebaseSettings?.find((c) => c.currency)?.currency;
    currency === undefined && (currency = "NZD");
    dispatch(setCurrency(currency));
    if (firebaseAssets && firebaseCurrencies && firebaseSettings) {
      assetStructure(firebaseAssets, firebaseCurrencies, firebaseSettings)
        .then((assets: IAsset[]) => {
          dispatch(setAssets(assets));
          setLoading(false);
        })
        .catch((err: Error) => console.log(err));
    }
  }, [firebaseSettings]);

  // setting assets
  useEffect(() => {
    if (firebaseAssets && firebaseCurrencies && firebaseSettings && loading) {
      assetStructure(firebaseAssets, firebaseCurrencies, firebaseSettings)
        .then((assets) => {
          dispatch(setAssets(assets));
          setLoading(false);
        })
        .catch((err: Error) => console.log(err));
    }
  }, [firebaseAssets, firebaseCurrencies, firebaseSettings]);

  // setting net worths
  useEffect(() => {
    if (firebaseHistoricalNws && firebaseCurrencies && firebaseSettings) {
      historicalNwStructure(
        firebaseHistoricalNws,
        firebaseCurrencies,
        firebaseSettings
      ).then((historicalNws: NetWorth[]) => {
        dispatch(setNws(historicalNws));
      });
    }
  }, [firebaseHistoricalNws, firebaseCurrencies, firebaseSettings]);

  useEffect(() => {
    if (firebaseCurrencies) {
      currencyStructure(firebaseCurrencies).then((currencies: Currency[]) =>
        dispatch(setCurrencies(currencies))
      );
    }
  }, [firebaseCurrencies]);

  return (
    <div className="dashboard">
      {loading ? (
        <div className="cover">
          <div className="spinner" />
        </div>
      ) : null}
      <div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 className="dashboard-title" onClick={signOut}>
              TickerTracker.co
            </h3>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginTop: "30px",
            marginBottom: "10px",
            flexWrap: "wrap",
          }}
        >
          <Total />
          <AddAsset />
        </div>
        <Portfolio />
        <div className="dashboard-bottom">
          <select
            onChange={(e) =>
              settingsRef.doc("currency").set({
                currency: e.target.value,
              })
            }
            value={firebaseSettings?.find((c) => c.currency)?.currency}
          >
            <option value="NZD">NZD $</option>
            <option value="AUD">AUD $</option>
            <option value="USD">USD $</option>
          </select>
          <button onClick={signOut}>Sign Out</button>
        </div>
        <Footer />
      </div>
    </div>
  );
}
