import { combineReducers } from "redux";
import { assetsReducer } from "./reducers/assetsReducer";
import { firestoreRefsReducer } from "./reducers/firestoreRefsReducer";
import { timeFrameReducer } from "./reducers/timeFrameReducer";
import { totalReducer } from "./reducers/totalReducer";
import { currencyReducer } from "./reducers/currencyReducer";
import { historicalNwsReducer } from "./reducers/historicalNwsReducer";
import { currenciesReducer } from "./reducers/currenciesReducer";

export const rootReducer = () =>
  combineReducers({
    assets: assetsReducer,
    total: totalReducer,
    currency: currencyReducer,
    firestoreRefs: firestoreRefsReducer,
    timeFrame: timeFrameReducer,
    historicalNws: historicalNwsReducer,
    currencies: currenciesReducer
  });
