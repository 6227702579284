import { ReactElement, useEffect, useState } from "react";
import "./Asset.scss";
import Modal from "react-modal";
import firebase from "firebase";
import { TrashCan } from "../../constants/Icons";
import { useDispatch, useSelector } from "react-redux";
import { setAsset, deleteAsset } from "../../redux/actions/assetsActions";
import { getAsset } from "../../functions/apiCalls";
import {
  LARGE_TABLE,
  MEDIUM_TABLE,
  SMALL_TABLE,
} from "../../constants/constants";
import AssetChart from "../AssetChart/AssetChart";

interface Props {
  asset: IAsset;
}

export default function Asset({ asset }: Props): ReactElement {
  const assetsRef = useSelector((state: any) => state.firestoreRefs.assetsRef);
  const currencyTo = useSelector((state: any) => state.currency);
  const [quantity, setQuantity] = useState<string>(asset.quantity.toString());
  const [modalIsOpen, setIsOpen] = useState(false);
  const [averageCost, setAverageCost] = useState<string>(
    asset.averageCost.toString()
  );
  const dispatch = useDispatch();
  const currencies = useSelector((state: any) => state.currencies);

  const onDeleteAsset = (symbol: string) => {
    if (window.confirm("Are you sure want to delete this asset?")) {
      dispatch(deleteAsset(symbol));
      assetsRef.doc(symbol).delete();
    }
  };

  useEffect(() => {
    setAverageCost(asset.averageCost.toString());
    setQuantity(asset.quantity.toString());
  }, [asset]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const editAsset = (e: any) => {
    e.preventDefault();
    if (Number(quantity) > 0) {
      getAsset(asset.symbol).then(async (data) => {
        const price = await getPrice(
          Number(data.regularMarketPrice),
          asset.currency
        );
        const dailyPercentageChange =
          (data.regularMarketPrice - data.previousClose) / data.previousClose;
        const tpc = (price - Number(averageCost)) / Number(averageCost);
        const totalPercentageChange = isFinite(tpc) ? tpc : 0.0;
        const dailyDollarChange =
          dailyPercentageChange * price * Number(quantity);
        const totalDollarChange =
          totalPercentageChange * Number(averageCost) * Number(quantity);

        dispatch(
          setAsset({
            symbol: asset.symbol,
            name: asset.name,
            quantity: Number(quantity),
            type: asset.type,
            averageCost: Number(averageCost),
            price: price,
            totalCost: Number(averageCost) * Number(quantity),
            totalPrice: price * Number(quantity),
            dailyPercentageChange: dailyPercentageChange,
            totalPercentageChange: totalPercentageChange,
            dailyDollarChange: dailyDollarChange,
            totalDollarChange: totalDollarChange,
            currency: asset.currency,
          })
        );
      });
      assetsRef.doc(asset.symbol).set({
        symbol: asset.symbol,
        name: asset.name,
        quantity: Number(quantity),
        type: asset.type,
        currency: asset.currency,
        averageCost: Number(averageCost),
        createAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      closeModal();
    }
  };

  const getPrice = (price: number, currencyFrom: string) => {
    const from = currencies?.find((c: any) => c.code === currencyFrom)?.rate;
    const to = currencies?.find((c: any) => c.code === currencyTo)?.rate;
    const currencyRate = to / from;
    return price * currencyRate;
  };

  return (
    <>
      <tr className="asset-row">
        {window.innerWidth > SMALL_TABLE && (
          <td onClick={openModal}>
            {asset.type === "CRYPTOCURRENCY"
              ? asset.symbol.slice(0, -4)
              : asset.symbol}
          </td>
        )}
        <td onClick={openModal}>
          {asset.type === "CRYPTOCURRENCY"
            ? asset.name.slice(0, -4)
            : asset.name.length > 13
            ? asset.name.slice(0, 13) + "..."
            : asset.name}
        </td>
        <td onClick={openModal}>{parseFloat(asset.quantity.toFixed(8))}</td>
        {window.innerWidth > MEDIUM_TABLE && (
          <td onClick={openModal}>
            $
            {asset.averageCost
              .toFixed(3)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </td>
        )}
        {window.innerWidth > MEDIUM_TABLE && (
          <td onClick={openModal}>
            $
            {asset.price
              .toFixed(3)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </td>
        )}
        {window.innerWidth > SMALL_TABLE && (
          <td onClick={openModal}>
            $
            {asset.totalCost
              .toFixed(3)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </td>
        )}
        <td onClick={openModal}>
          $
          {asset.totalPrice
            .toFixed(3)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </td>
        {window.innerWidth > LARGE_TABLE && (
          <td
            onClick={openModal}
            style={{
              color: asset.dailyDollarChange >= 0 ? "#60E0C7" : "#CE4E78",
            }}
          >
            {asset.dailyDollarChange < 0 && "-"}$
            {Math.abs(asset.dailyDollarChange).toFixed(3)}
          </td>
        )}
        <td
          onClick={openModal}
          style={{
            color: asset.dailyPercentageChange >= 0 ? "#60E0C7" : "#CE4E78",
          }}
        >
          {asset.dailyPercentageChange >= 0 && "+"}
          {(asset.dailyPercentageChange * 100).toFixed(2)}%
        </td>
        {window.innerWidth > LARGE_TABLE && (
          <td
            onClick={openModal}
            style={{
              color: asset.totalDollarChange >= 0 ? "#60E0C7" : "#CE4E78",
            }}
          >
            {asset.totalDollarChange < 0 && "-"}$
            {Math.abs(asset.totalDollarChange).toFixed(3)}
          </td>
        )}
        {window.innerWidth > SMALL_TABLE && (
          <td
            onClick={openModal}
            style={{
              color: asset.totalPercentageChange >= 0 ? "#60E0C7" : "#CE4E78",
            }}
          >
            {asset.totalPercentageChange >= 0 && "+"}
            {(asset.totalPercentageChange * 100).toFixed(2)}%
          </td>
        )}
      </tr>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="addAsset"
        ariaHideApp={false}
        className="addAsset-modalContent"
        overlayClassName="addAsset-modalOverlay"
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1 className="addAsset-header">
            $
            {asset.type === "CRYPTOCURRENCY"
              ? asset.symbol.slice(0, -4)
              : asset.symbol}
          </h1>
          <button
            style={{ height: "40px" }}
            onClick={() => {
              onDeleteAsset(asset.symbol);
            }}
          >
            <TrashCan />
          </button>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ margin: "5px", marginBottom: "20px", color: "#707383" }}>
            Price: ${asset.price.toFixed(3)}
            {currencyTo.toLowerCase()}
          </p>
          <p style={{ margin: "5px", marginBottom: "20px", color: "#707383" }}>
            1 Year Chart
          </p>
        </div>
        <AssetChart asset={asset} />
        <form onSubmit={editAsset} className="addAsset-form">
          <label className="addAsset-label" htmlFor="quantity">
            Quantity
          </label>
          <input
            className="addAsset-input"
            id="quantity"
            type="text"
            pattern="^\d+(\.\d{1,8})?$"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            placeholder="eg: 1000"
          />
          <h3 className="addAsset-price">
            ${(asset.price * Number(quantity)).toFixed(3)}
            {currencyTo.toLowerCase()} total
          </h3>
          <label className="addAsset-label" htmlFor="averageCost">
            Average Cost per Unit ({currencyTo} $) - Optional
          </label>
          <input
            className="addAsset-input"
            id="averageCost"
            type="text"
            pattern="^\d+(\.\d{1,8})?$"
            value={averageCost}
            onChange={(e) => setAverageCost(e.target.value)}
            placeholder="eg: 100"
          />
          <button type="submit" className="addAsset-button">
            Update Asset
          </button>
        </form>
      </Modal>
    </>
  );
}
