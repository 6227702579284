export const setAsset = (asset: IAsset) => {
  return { type: "SET_ASSET", payload: asset}
};

export const deleteAsset = (symbol: string) => {
  return { type: "DELETE_ASSET", payload: symbol}
};

export const setAssets = (assets: IAsset[]) => {
  return { type: "SET_ASSETS", payload: assets}
};


