export const initialState = [];

export const assetsReducer = (state: IAsset[] = initialState, action: any) => {
  switch (action.type) {
    case "SET_ASSET": {
      if (state.some((a) => a.symbol === action.payload.symbol)) {
        return state.map((a) =>
          a.symbol === action.payload.symbol ? action.payload : a
        );
      } else {
        return [...state, action.payload];
      }
    }
    case "DELETE_ASSET": {
      return state.filter((asset) => asset.symbol !== action.payload);
    }
    case "SET_ASSETS": {
      return action.payload;
    }
    default:
      return state;
  }
};
