import React, { ReactElement, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { getAssetHistory } from "../../functions/apiCalls";
import "./AssetChart.scss";

interface Props {
  asset: IAsset;
}

export default function AssetChart({ asset }: Props): ReactElement {
  const [data, setData] = useState<any[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const currencyTo = useSelector((state: any) => state.currency);
  const currencies = useSelector((state: any) => state.currencies);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAssetHistory(asset.symbol).then((d: { priceHistory: any[]; dateHistory: any[] }) => {
      let dataList: any[] = [];
      let labelList: string[] = [];
      const currencyRate = getCurrencyRate();
      d.priceHistory.forEach((price, i) => {
        const date = d.dateHistory[i];
        const p = price ? price * currencyRate : null;
        dataList.push(p);
        labelList.push(new Date(date * 1000).toDateString().slice(4, 10));
      });
      setData([...dataList]);
      setLabels([...labelList]);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies]);

  const getCurrencyRate = () => {
    const from = currencies?.find((c: any) => c.code === asset.currency)?.rate;
    const to = currencies?.find((c: any) => c.code === currencyTo)?.rate;
    return to / from;
  };

  return (
    <div className="assetChart">
      {loading ? (
        <div className="cover">
          <div className="spinner" />
        </div>
      ) : null}
      <Line
        type="line"
        data={{
          labels: labels,
          datasets: [
            {
              fill: false,
              lineTension: 0.1,
              backgroundColor: "#5EB5F8",
              borderColor: "#5EB5F8",
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "#5EB5F8",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "#5EB5F8",
              pointHoverBorderWidth: 2,
              pointRadius: 0,
              pointHitRadius: 10,
              data: data,
            },
          ],
        }}
        options={{
          tooltips: {
            intersect: false,
            callbacks: {
              label: function (tooltipItem: any, data: any) {
                var label = data.datasets[tooltipItem.datasetIndex].label || "";
                label += tooltipItem.yLabel;
                return "Price $" + parseFloat(label).toFixed(2);
              },
            },
          },
          animation: {
            duration: 0,
          },
          spanGaps: true,
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: "#999999",
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 4,
                  maxRotation: 0,
                  minRotation: 0,
                  fontFamily: "Nunito",
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  color: "#999999",
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 8,
                  fontFamily: "Nunito",
                  callback: function (value: any) {
                    return "$" + value;
                  },
                },
              },
            ],
          },
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            display: false,
          },
        }}
      />
    </div>
  );
}
