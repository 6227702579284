import { Dispatch } from "redux";
import {
  setAssetsRef,
  setCurrenciesRef,
  setHistoricalNWsRef,
  setSettingsRef,
} from "../redux/actions/firestoreRefsActions";
import { auth, firestore } from "../firebase";

export const setRefs = async (dispatch: Dispatch<any>) => {
  dispatch(
    setSettingsRef(
      firestore.collection(`users/${auth.currentUser!.uid}/settings`)
    )
  );
  dispatch(setCurrenciesRef(firestore.collection("currencies")));
  dispatch(
    setAssetsRef(firestore.collection(`users/${auth.currentUser!.uid}/assets`))
  );
  dispatch(
    setHistoricalNWsRef(
      firestore
        .collection(`users/${auth.currentUser!.uid}/historicalNWs`)
        .orderBy("createAt")
    )
  );
};
