import React, { ReactElement } from "react";
import { auth } from "../../firebase";
import firebase from "firebase";

export default function Login(): ReactElement {
  const signInWithGoogle = () => {
    auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  };

  return (
    <div>
      <h1>Login/Register</h1>
      <button
        onClick={signInWithGoogle}
        style={{
          width: "100%",
          padding: "10px",
          fontSize: "15px",
          fontWeight: "bold",
          backgroundColor: "#5EB5F8",
          color: "#2d2d38",
        }}
      >
        Sign In With Google
      </button>
      <p style={{ margin: "10px", color: "#707383" }}>
        By signing up you are opting into getting emails regarding updates to
        Ticker Tracker. If you would like to opt out click unsubscribe on any
        email you receive.
      </p>
    </div>
  );
}
