export const initialState = 2;

export const timeFrameReducer = (state: number = initialState, action: any) => {
  switch (action.type) {
    case "SET_TIME_FRAME": {
      return action.payload;
    }
    default:
      return state;
  }
};
