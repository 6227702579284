import React, { ReactElement } from "react";
import "./Footer.scss"

export default function Footer(): ReactElement {
  return (
    <div className="footer">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://josh.work"
        title="Personal Website"
      >
        Built by Josh Richards
      </a>
      <a href="mailto:tickertrackercontact@gmail.com" title="Email">
        tickertrackercontact@gmail.com
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.buymeacoffee.com/joshuarichards"
        title="Support Me"
      >
        Buy Me a Coffee!
      </a>
    </div>
  );
}
