import React, { ReactElement, useState } from "react";
import AutoSuggest from "react-autosuggest";
import { getAsset } from "../../functions/apiCalls";
import { useSelector } from "react-redux";
import "./AutoComplete.scss";
import {
  PROXY,
  SEARCH_META,
  YAHOO_SEARCH_BASE_URL,
} from "../../constants/urls";
import { EXCHANGE_LIST } from "../../constants/constants";

interface Props {
  setTickerData: any;
  setDailyChange: any;
  setPrice: any;
}

interface Suggestion {
  suggestion: ApiSearchSuggestion;
}

export default function AutoComplete({
  setTickerData,
  setDailyChange,
  setPrice,
}: Props): ReactElement {
  const [suggestions, setSuggestions] = useState<ApiSearchSuggestion[]>([]);
  const [symbol, setSymbol] = useState("");
  const currencyTo = useSelector((state: any) => state.currency);
  const currencies = useSelector((state: any) => state.currencies);

  const getPrice = (e: any, { suggestion }: Suggestion) => {
    getAsset(suggestion.symbol.replace(/<\/?[^>]+(>|$)/g, ""))
      .then((data) => {
        const selected = {
          symbol: suggestion.symbol.toUpperCase(),
          description: suggestion.longname
            ? suggestion.longname
            : suggestion.shortname,
          currency: data.currency.toUpperCase(),
          type: suggestion.quoteType,
        };
        setTickerData(selected);
        setDailyChange(
          Number(
            (data.regularMarketPrice - data.previousClose) / data.previousClose
          )
        );
        setPrice(
          getCurrencyAdjustedPrice(
            Number(data.regularMarketPrice),
            selected.currency
          )
        );
      })
      .catch((err: Error) => {
        console.log(err);
      });
  };

  const getCurrencyAdjustedPrice = (price: number, currencyFrom: string) => {
    const from = currencies?.find((c: any) => c.code === currencyFrom)?.rate;
    const to = currencies?.find((c: any) => c.code === currencyTo)?.rate;
    const currencyRate = to / from;
    return price * currencyRate;
  };

  const getSuggestions = async (value: string) => {
    await fetch(
      PROXY +
        `${encodeURIComponent(YAHOO_SEARCH_BASE_URL + value + SEARCH_META)}`
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then((data: any) => {
        setSuggestions(
          JSON.parse(data.contents)
            .quotes.filter((s: ApiSearchSuggestion) =>
              EXCHANGE_LIST.includes(s.exchange)
            )
            .filter((s: any) =>
              ["Equity", "ETF", "Cryptocurrency", "Fund"].includes(s.typeDisp)
            )
        );
      })
      .catch((e) => {
        return [];
      });
  };

  return (
    <>
      <AutoSuggest
        id="ticker"
        suggestions={suggestions}
        highlightFirstSuggestion={true}
        onSuggestionsFetchRequested={({ value }: any) =>
          getSuggestions(value).then((data: any) => {
            if (data !== undefined) {
              setSuggestions(data);
            }
          })
        }
        onSuggestionSelected={getPrice}
        onSuggestionsClearRequested={() => setSuggestions([])}
        getSuggestionValue={(suggestion: any) => suggestion.symbol}
        renderSuggestion={(suggestion: ApiSearchSuggestion) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
            }}
          >
            <div>
              {suggestion.symbol} -{" "}
              {suggestion.quoteType === "CRYPTOCURRENCY"
                ? suggestion.shortname.slice(0, -4)
                : suggestion.longname
                ? suggestion.longname
                : suggestion.shortname}
            </div>
            <div style={{ color: "grey" }}>({suggestion.exchange})</div>
          </div>
        )}
        inputProps={{
          placeholder: "eg: 'Facebook Inc' or 'FB'",
          value: symbol,
          onChange: (event: any, { newValue }: any) => {
            setTickerData(null);
            setPrice(0);
            setSymbol(newValue);
          },
        }}
      />
    </>
  );
}
