export const initialState = [];

export const historicalNwsReducer = (
  state: NetWorth[] = initialState,
  action: any
) => {
  switch (action.type) {
    case "ADD_NW": {
      return [...state, action.payload];
    }
    case "SET_NWS": {
      return action.payload;
    }
    default:
      return state;
  }
};
