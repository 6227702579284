export const initialState = {
  assetsRef: null,
  historicalNWsRef: null,
  currenciesRef: null,
  settingsRef: null,
};

export const firestoreRefsReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case "SET_ASSETS_REF": {
      return { ...state, assetsRef: action.payload };
    }
    case "SET_HISTORICALS_REF": {
      return { ...state, historicalNWsRef: action.payload };
    }
    case "SET_CURRENCIES_REF": {
      return { ...state, currenciesRef: action.payload };
    }
    case "SET_SETTINGS_REF": {
      return { ...state, settingsRef: action.payload };
    }
    default:
      return state;
  }
};
