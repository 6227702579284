import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTotal } from "../../redux/actions/totalActions";
import "./Total.scss";

export default function Total(): ReactElement {
  const total = useSelector((state: any) => state.total);
  const assets = useSelector((state: any) => state.assets);
  const [changePercent, setChangePercent] = useState(0);
  const [change, setChange] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const t = assets.reduce((a: any, b: any) => a + b.price * b.quantity, 0);
    dispatch(setTotal(t));
    if (assets?.length) {
      let nw = 0;
      let nwChange = 0;
      assets.forEach((a: IAsset) => {
        nw += a.totalPrice;
        nwChange += a.dailyDollarChange;
      });
      const nwChangePercentage = nwChange / nw;
      setChangePercent(nwChangePercentage);
      setChange(nwChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets]);

  return (
    <div>
      <h3 className="total-subtitle">Net Worth: </h3>
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <h2 className="total-networth">${total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
        <p className="total-change" style={{ color: changePercent >= 0 ? "#60E0C7" : "#CE4E78" }}>
          ({changePercent < 0 && "-"}${Math.abs(change).toFixed(2)}
        </p>
        <p className="total-change" style={{ color: changePercent >= 0 ? "#60E0C7" : "#CE4E78" }}>
          {changePercent >= 0 && "+"}
          {changePercent.toFixed(2)}%)
        </p>
      </div>
    </div>
  );
}
