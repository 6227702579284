export const EXCHANGE_LIST = [
  "NMS",
  "NGM",
  "BTS",
  "NCM",
  "NYQ",
  "NAS",
  "ASX",
  "PCX",
  "NZE",
  "TOR",
  "LSE",
  "CCC",
];
export const BASE_CURRENCY = "NZD";
export const LARGE_TABLE = 1500;
export const MEDIUM_TABLE = 1200;
export const SMALL_TABLE = 1000;
