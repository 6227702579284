import { ReactElement } from "react";
import { useState } from "react";
import firebase from "firebase";
import Modal from "react-modal";
import "./AddAsset.scss";
import AutoComplete from "../AutoComplete/AutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { setAsset } from "../../redux/actions/assetsActions";

export default function AddAsset(): ReactElement {
  const assetsRef = useSelector((state: any) => state.firestoreRefs.assetsRef);
  const assets = useSelector((state: any) => state.assets);
  const currencyTo = useSelector((state: any) => state.currency);
  const [tickerData, setTickerData] = useState<any>(null);
  const [quantity, setQuantity] = useState<string>("");
  const [averageCost, setAverageCost] = useState<string>("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [price, setPrice] = useState(0);
  const [dailyPercentageChange, setDailyChange] = useState(0);
  const dispatch = useDispatch();
  // const searchClient = algoliasearch(
  //   "CKLPHFQ9VY",
  //   "a526e1f23dcc4991c2c85ca5a76e4eb3"
  // );

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setQuantity("");
    setTickerData(null);
    setPrice(0);
    setIsOpen(false);
  }

  const addAssetToAssets = (e: any) => {
    e.preventDefault();
    if (
      tickerData !== null &&
      tickerData !== undefined &&
      Number(quantity) > 0
    ) {
      if (assets.length <= 100) {
        const q =
          assets.find((a: any) => a.symbol === tickerData.symbol)?.quantity ||
          0;

        const tpc = (price - Number(averageCost)) / Number(averageCost);
        const totalPercentageChange = isFinite(tpc) ? tpc : 0.0;
        const dailyDollarChange =
          dailyPercentageChange * price * Number(quantity);
        const totalDollarChange =
          totalPercentageChange * Number(averageCost) * Number(quantity);

        dispatch(
          setAsset({
            symbol: tickerData.symbol,
            name: tickerData.description,
            quantity: Number(quantity) + q,
            type: tickerData.type,
            averageCost: Number(averageCost),
            price: price,
            totalCost: Number(averageCost) * Number(quantity),
            totalPrice: price * Number(quantity),
            dailyPercentageChange: dailyPercentageChange,
            totalPercentageChange: totalPercentageChange,
            dailyDollarChange: dailyDollarChange,
            totalDollarChange: totalDollarChange,
            currency: tickerData.currency,
          })
        );
        assetsRef.doc(tickerData.symbol).set({
          symbol: tickerData.symbol,
          name: tickerData.description,
          quantity: Number(quantity) + q,
          type: tickerData.type,
          currency: tickerData.currency,
          averageCost: Number(averageCost),
          createAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        setQuantity("");
        setAverageCost("");
        setTickerData(null);
        setPrice(0);
        closeModal();
      } else {
        window.confirm(
          "Currently you can only have upto 100 assets at a time, please email tickertrackercontact@gmail.com if this is an issue"
        );
      }
    }
  };

  return (
    <div className="addAsset-container">
      <button className="addAsset-dashboard-button" onClick={openModal}>
        Add Asset
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="addAsset"
        ariaHideApp={false}
        className="addAsset-modalContent"
        overlayClassName="addAsset-modalOverlay"
      >
        <h1 className="addAsset-header">Add Asset</h1>
        <form onSubmit={addAssetToAssets} className="addAsset-form">
          <label className="addAsset-label" htmlFor="ticker">
            Name
          </label>
          <AutoComplete
            setTickerData={setTickerData}
            setDailyChange={setDailyChange}
            setPrice={setPrice}
          />
          <h3 className="addAsset-price">${price.toFixed(3)} per unit</h3>
          <label className="addAsset-label" htmlFor="quantity">
            Quantity
          </label>
          <input
            className="addAsset-input"
            id="quantity"
            type="text"
            pattern="^\d+(\.\d{1,8})?$"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            placeholder="eg: 10"
          />
          <h3 className="addAsset-price">
            ${(price * Number(quantity)).toFixed(3)}
            {currencyTo.toLowerCase()} total
          </h3>
          <label className="addAsset-label" htmlFor="averageCost">
            Average Cost per Unit ({currencyTo} $) - Optional
          </label>
          <input
            className="addAsset-input"
            id="averageCost"
            type="text"
            pattern="^\d+(\.\d{1,8})?$"
            value={averageCost}
            onChange={(e) => setAverageCost(e.target.value)}
            placeholder="eg: 100"
          />
          <button type="submit" className="addAsset-button">
            Add Asset
          </button>
        </form>
      </Modal>
    </div>
  );
}
