import { Data } from "react-firebase-hooks/firestore/dist/firestore/types";
import firebase from "firebase";

export default async function currencyStructure(
  firebaseCurrencies: Data<firebase.firestore.DocumentData, "", "">[],
) {
  let currencies: Currency[] = [];
  for (const currency of firebaseCurrencies) {
    currencies.push({
      code: currency.code,
      rate: currency.rate
    })
  }
  return currencies;
}
