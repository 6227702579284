import React, { ReactElement, useState } from "react";
import ttImage from "../../constants/Images/ttImage.png";
import addAssetImage from "../../constants/Images/addAssetImage.png";
import "./LandingPage.scss";
import Modal from "react-modal";
import Login from "../Login/Login";
import Footer from "../Footer/Footer";

export default function LandingPage(): ReactElement {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="landing">
      <div>
        <div className="landing-header">
          <h3 className="title">TickerTracker.co</h3>
          <button onClick={openModal}>Log In</button>
        </div>

        <div className="landing-body">
          <h2 className="landing-subheader">
            All your assets together in one place
          </h2>
          <img src={ttImage} alt="" className="landing-image" />
          <button onClick={openModal} className="landing-login">
            Get Started
          </button>
          <p className="landing-paragraph">
            Ticker Tracker is a portfolio management platform. It helps you
            aggregate all of your investments into one place and track their
            performance over time. It is built with a high emphasis on user
            privacy and security so you know your asset information will be
            safe. The platform is completely free so Log In using Google to try
            it out today!
          </p>
          <img src={addAssetImage} alt="" className="landing-sub-image" />
          <p className="landing-exchanges">
            NASDAQ | NYSE | NSX | ASX | LSE | TSX | CRYPTO
          </p>
          <p className="landing-paragraph">
            We support thousands of stocks, funds and cryptocurrencies from from
            a variety of exchanges so that you can see how your different asset
            classes stack up against each other.
          </p>
          <Footer />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        className="login-modalContent"
        overlayClassName="login-modalOverlay"
      >
        <Login />
      </Modal>
    </div>
  );
}
