import { Data } from "react-firebase-hooks/firestore/dist/firestore/types";
import firebase from "firebase";
import { BASE_CURRENCY } from "../constants/constants";

export default async function historicalNwStructure(
  firebaseHistoricalNws: Data<firebase.firestore.DocumentData, "", "">[],
  firebaseCurrencies: Data<firebase.firestore.DocumentData, "", "">[],
  firebaseSettings: Data<firebase.firestore.DocumentData, "", "">[]
) {
  const today = new Date().toDateString().slice(4, 10);
  let nws: NetWorth[] = [];
  let currency: string = firebaseSettings?.find((c) => c.currency)?.currency;
  currency === undefined && (currency = "NZD");
  for (const nw of firebaseHistoricalNws) {
    const date = new Date(nw.createAt.seconds * 1000)
      .toDateString()
      .slice(4, 10);
    if (date !== today && !nwExists(nws, date) && nw.netWorth) {
      nws.push({
        netWorth: getPrice(
          Number(nw.netWorth),
          BASE_CURRENCY,
          currency,
          firebaseCurrencies
        ),
        date: date,
      });
    }
  }
  return Array.from(new Set(nws));
}

function nwExists(nws: NetWorth[], date: string) {
  return nws.some((nw: NetWorth) => nw.date === date);
}

const getPrice = (
  price: number,
  currencyFrom: string,
  currencyTo: string,
  firebaseCurrencies: any
) => {
  const from = firebaseCurrencies.find((c: any) => {
    return c.code === currencyFrom;
  }).rate;
  const to = firebaseCurrencies.find((c: any) => {
    return c.code === currencyTo;
  }).rate;
  const currencyRate = to / from;
  return price * currencyRate;
};
