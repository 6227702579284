/* eslint-disable react-hooks/exhaustive-deps */
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import "./styles/_global.scss";
import "./styles/_elements.scss";
import LandingPage from "./components/LandingPage/LandingPage";
import { Coin } from "./constants/Icons";
import ReactGA from "react-ga";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Dashboard from "./components/Dashboard/Dashboard";

export default function App() {
  const user = useAuthState(auth);
  let history = useHistory();

  useEffect(() => {
    user[0] && history.push("/dashboard");
  }, [user]);

  useEffect(() => {
    ReactGA.initialize("UA-178390544-3");
    ReactGA.pageview("/");
  }, []);

  function Loading() {
    return (
      <div className="cover">
        <div className="logo">
          <Coin />
        </div>
      </div>
    );
  }

  return <div className="app">{user[1] ? <Loading /> : user[0] ? <Dashboard /> : <LandingPage />}</div>;
}
