import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

firebase.initializeApp({
  apiKey: "AIzaSyBZtuKqcmFTz2ySd3rBuBJocPmdpYKJgpw",
  authDomain: "portfolio-tracker-f3d40.firebaseapp.com",
  projectId: "portfolio-tracker-f3d40",
  storageBucket: "portfolio-tracker-f3d40.appspot.com",
  messagingSenderId: "781809229267",
  appId: "1:781809229267:web:ca3f7fdd78aa66a5612326",
  measurementId: "G-TT0TEKQ28D",
});

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();

export default firebase;
