// IEX Stuff
// export const PUB_KEY = "Tpk_f180be51ed4043f4ade3e536ec4b40f2";
// export const baseUrl = "https://sandbox.iexapis.com/stable";
export const PUB_KEY = "pk_3618f47c6222477c8f646fa78d8711fb";
export const baseUrl = "https://cloud.iexapis.com/stable";

const tokenString = `?token=${PUB_KEY}`;
export const stockSymbols = "/ref-data/symbols" + tokenString;
export const cryptoSymbols = "/ref-data/crypto/symbols" + tokenString;

// Fixer
export const FIXER_API_KEY = "641792ed93d7efda5cd5169c70e56069";

export const fixer_baseUrl =
  "http://data.fixer.io/api/latest?access_key="+FIXER_API_KEY+"";


// Yahoo Finance Stuff
export const PROXY = "https://api.allorigins.win/get?url=";
export const YAHOO_QUOTE_BASE_URL = "https://query1.finance.yahoo.com/v8/finance/chart/";
export const QUOTE_META = "?region=NZ&lang=en-NZ&includePrePost=false&interval=2m&useYfid=true&range=1d&corsDomain=finance.yahoo.com&.tsrc=finance"
export const CHART_META = "?region=NZ&lang=en-NZ&includePrePost=false&interval=1d&useYfid=true&range=1y&corsDomain=nz.finance.yahoo.com&.tsrc=finance"

export const YAHOO_SEARCH_BASE_URL = "https://query2.finance.yahoo.com/v1/finance/search?q="
export const SEARCH_META = "&quotesCount=6&newsCount=0&enableFuzzyQuery=false&quotesQueryId=tss_match_phrase_query&multiQuoteQueryId=multi_quote_single_token_query&newsQueryId=news_ss_symbols&enableCb=false&enableNavLinks=false&vespaNewsTimeoutMs=600"