/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useState } from "react";
import Asset from "../Asset/Asset";
import "./Group.scss";
import { DownArrow, RightArrow, SortArrows } from "../../constants/Icons";
import { useSelector } from "react-redux";
import {
  LARGE_TABLE,
  MEDIUM_TABLE,
  SMALL_TABLE,
} from "../../constants/constants";

interface Props {
  title: string;
  type: string;
}

export default function Group({ title, type }: Props): ReactElement {
  const assetList = useSelector((state: any) => state.assets);
  const total = useSelector((state: any) => state.total);
  const [weight, setWeight] = useState(0);
  const [groupTotal, setGroupTotal] = useState(0);
  const [assets, setAssets] = useState<IAsset[]>([...assetList]);
  const [sort, setSort] = useState(false);
  const [open, setOpen] = useState(true);
  const [sortColumn, setSortColumn] = useState("symbol");

  useEffect(() => {
    setAssets([
      ...assetList.filter((asset: any) => {
        return type === asset.type;
      }),
    ]);
  }, [assetList]);

  useEffect(() => {
    setWeight(
      (assets.reduce((a, b) => a + b.price * b.quantity, 0) / total) * 100
    );
    setGroupTotal(assets.reduce((a, b) => a + b.price * b.quantity, 0));
  }, [assets]);

  const onSort = (column: string) => {
    setSort(!sort);
    switch (column) {
      case "symbol":
        sort
          ? setAssets([
              ...assets.sort((a, b) => a.symbol.localeCompare(b.symbol)),
            ])
          : setAssets([
              ...assets.sort((a, b) => b.symbol.localeCompare(a.symbol)),
            ]);
        return;
      case "name":
        sort
          ? setAssets([...assets.sort((a, b) => a.name.localeCompare(b.name))])
          : setAssets([...assets.sort((a, b) => b.name.localeCompare(a.name))]);
        return;
      case "quantity":
        sort
          ? setAssets([...assets.sort((a, b) => a.quantity - b.quantity)])
          : setAssets([...assets.sort((a, b) => b.quantity - a.quantity)]);
        return;
      case "price":
        sort
          ? setAssets([...assets.sort((a, b) => a.price - b.price)])
          : setAssets([...assets.sort((a, b) => b.price - a.price)]);
        return;
      case "avgCost":
        sort
          ? setAssets([...assets.sort((a, b) => a.averageCost - b.averageCost)])
          : setAssets([
              ...assets.sort((a, b) => b.averageCost - a.averageCost),
            ]);
        return;
      case "totalCost":
        sort
          ? setAssets([...assets.sort((a, b) => a.totalCost - b.totalCost)])
          : setAssets([...assets.sort((a, b) => b.totalCost - a.totalCost)]);
        return;
      case "totalPrice":
        sort
          ? setAssets([...assets.sort((a, b) => a.totalPrice - b.totalPrice)])
          : setAssets([...assets.sort((a, b) => b.totalPrice - a.totalPrice)]);
        return;
      case "dailyDollarChange":
        sort
          ? setAssets([
              ...assets.sort(
                (a, b) => a.dailyDollarChange - b.dailyDollarChange
              ),
            ])
          : setAssets([
              ...assets.sort(
                (a, b) => b.dailyDollarChange - a.dailyDollarChange
              ),
            ]);
        return;
      case "dailyPercentageChange":
        sort
          ? setAssets([
              ...assets.sort(
                (a, b) => a.dailyPercentageChange - b.dailyPercentageChange
              ),
            ])
          : setAssets([
              ...assets.sort(
                (a, b) => b.dailyPercentageChange - a.dailyPercentageChange
              ),
            ]);
        return;
      case "totalDollarChange":
        sort
          ? setAssets([
              ...assets.sort(
                (a, b) => a.totalDollarChange - b.totalDollarChange
              ),
            ])
          : setAssets([
              ...assets.sort(
                (a, b) => b.totalDollarChange - a.totalDollarChange
              ),
            ]);
        return;
      case "totalPercentageChange":
        sort
          ? setAssets([
              ...assets.sort(
                (a, b) => a.totalPercentageChange - b.totalPercentageChange
              ),
            ])
          : setAssets([
              ...assets.sort(
                (a, b) => b.totalPercentageChange - a.totalPercentageChange
              ),
            ]);
        return;
    }
  };

  return (
    <>
      {assets.length > 0 && (
        <div className="group">
          {open ? (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                cursor: "pointer",
              }}
              onClick={() => setOpen(!open)}
            >
              <DownArrow />
              <h2 style={{ marginTop: "-13px" }}>{title}</h2>
              <p style={{ marginLeft: "10px" }}>
                (${groupTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
              <p style={{ marginLeft: "10px" }}>{weight.toFixed(2)}%)</p>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                cursor: "pointer",
              }}
              onClick={() => setOpen(!open)}
            >
              <RightArrow />
              <h2 style={{ marginTop: "-13px" }}>{title}</h2>
              <p style={{ marginLeft: "10px" }}>
                (${groupTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
              <p style={{ marginLeft: "10px" }}>{weight.toFixed(2)}%)</p>
            </div>
          )}
          {open && (
            <table id="table">
              {window.innerWidth > LARGE_TABLE && (
                <colgroup>
                  <col className="one" />
                  <col className="two" />
                  <col className="one" />
                  <col className="one" />
                  <col className="one" />
                  <col className="one" />
                  <col className="one" />
                  <col className="one" />
                  <col className="one" />
                  <col className="one" />
                  <col className="one" />
                </colgroup>
              )}
              {window.innerWidth > MEDIUM_TABLE &&
                window.innerWidth < LARGE_TABLE && (
                  <colgroup>
                    <col className="one" />
                    <col className="two" />
                    <col className="one" />
                    <col className="one" />
                    <col className="one" />
                    <col className="one" />
                    <col className="one" />
                    <col className="one" />
                    <col className="one" />
                  </colgroup>
                )}
              {window.innerWidth > SMALL_TABLE &&
                window.innerWidth < MEDIUM_TABLE && (
                  <colgroup>
                    <col className="one" />
                    <col className="two" />
                    <col className="one" />
                    <col className="one" />
                    <col className="one" />
                    <col className="one" />
                    <col className="one" />
                  </colgroup>
                )}
              {window.innerWidth < SMALL_TABLE && (
                <colgroup>
                  <col className="two" />
                  <col className="one" />
                  <col className="one" />
                  <col className="one" />
                </colgroup>
              )}
              <thead>
                <tr className="group-header">
                  {window.innerWidth > SMALL_TABLE && (
                    <th
                      onClick={() => {
                        setSortColumn("symbol");
                        onSort("symbol");
                      }}
                    >
                      Symbol
                      {sortColumn === "symbol" && <SortArrows />}
                    </th>
                  )}
                  <th
                    onClick={() => {
                      setSortColumn("name");
                      onSort("name");
                    }}
                  >
                    Name
                    {sortColumn === "name" && <SortArrows />}
                  </th>
                  <th
                    onClick={() => {
                      setSortColumn("quantity");
                      onSort("quantity");
                    }}
                  >
                    Units
                    {sortColumn === "quantity" && <SortArrows />}
                  </th>
                  {window.innerWidth > MEDIUM_TABLE && (
                    <th
                      onClick={() => {
                        setSortColumn("avgCost");
                        onSort("avgCost");
                      }}
                    >
                      Avg. Cost
                      {sortColumn === "avgCost" && <SortArrows />}
                    </th>
                  )}
                  {window.innerWidth > MEDIUM_TABLE && (
                    <th
                      onClick={() => {
                        setSortColumn("price");
                        onSort("price");
                      }}
                    >
                      Unit Price
                      {sortColumn === "price" && <SortArrows />}
                    </th>
                  )}
                  {window.innerWidth > SMALL_TABLE && (
                    <th
                      onClick={() => {
                        setSortColumn("totalCost");
                        onSort("totalCost");
                      }}
                    >
                      Total Cost
                      {sortColumn === "totalCost" && <SortArrows />}
                    </th>
                  )}
                  <th
                    onClick={() => {
                      setSortColumn("totalPrice");
                      onSort("totalPrice");
                    }}
                  >
                    Total Price
                    {sortColumn === "totalPrice" && <SortArrows />}
                  </th>
                  {window.innerWidth > LARGE_TABLE && (
                    <th
                      onClick={() => {
                        setSortColumn("dailyDollarChange");
                        onSort("dailyDollarChange");
                      }}
                    >
                      $24h Δ
                      {sortColumn === "dailyDollarChange" && <SortArrows />}
                    </th>
                  )}
                  <th
                    onClick={() => {
                      setSortColumn("dailyPercentageChange");
                      onSort("dailyPercentageChange");
                    }}
                  >
                    24h% Δ
                    {sortColumn === "dailyPercentageChange" && <SortArrows />}
                  </th>
                  {window.innerWidth > LARGE_TABLE && (
                    <th
                      onClick={() => {
                        setSortColumn("totalDollarChange");
                        onSort("totalDollarChange");
                      }}
                    >
                      $Total Δ
                      {sortColumn === "totalDollarChange" && <SortArrows />}
                    </th>
                  )}
                  {window.innerWidth > SMALL_TABLE && (
                    <th
                      onClick={() => {
                        setSortColumn("totalPercentageChange");
                        onSort("totalPercentageChange");
                      }}
                    >
                      Total% Δ
                      {sortColumn === "totalPercentageChange" && <SortArrows />}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {assets?.map((asset: any) => (
                  <Asset key={asset.symbol} asset={asset} />
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </>
  );
}
