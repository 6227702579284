import { Data } from "react-firebase-hooks/firestore/dist/firestore/types";
import firebase from "firebase";
import { getAsset } from "./apiCalls";

export default async function assetStructure(
  fAssets: Data<firebase.firestore.DocumentData, "", "">[],
  fCurrencies: Data<firebase.firestore.DocumentData, "", "">[],
  fSettings: Data<firebase.firestore.DocumentData, "", "">[]
) {
  let assets: IAsset[] = [];
  let currency: string = fSettings?.find((c) => c.currency)?.currency;
  currency === undefined && (currency = "NZD");

  const promises = [];
  for (const asset of fAssets!) {
    promises.push(getAsset(asset.symbol));
  }

  const results = await Promise.all(promises);
  results.forEach(async (result, i) => {
    const price = await getPrice(
      Number(result.regularMarketPrice),
      fAssets[i].currency,
      currency,
      fCurrencies
    );
    const dailyPercentageChange =
      (result.regularMarketPrice - result.previousClose) / result.previousClose;
    const tpc =
      (price - (fAssets[i].averageCost || 0)) / (fAssets[i].averageCost || 0);
    const totalPercentageChange = isFinite(tpc) ? tpc : 0.0;
    const dailyDollarChange =
      dailyPercentageChange * price * fAssets[i].quantity;
    const totalDollarChange =
      totalPercentageChange *
      (fAssets[i].averageCost || 0) *
      fAssets[i].quantity;

    assets.push({
      symbol: fAssets[i].symbol,
      name: fAssets[i].name,
      quantity: fAssets[i].quantity,
      type: fAssets[i].type,
      averageCost: fAssets[i].averageCost || 0,
      price: price,
      totalCost: (fAssets[i].averageCost || 0) * fAssets[i].quantity,
      totalPrice: price * fAssets[i].quantity,
      dailyPercentageChange: dailyPercentageChange,
      totalPercentageChange: totalPercentageChange,
      dailyDollarChange: dailyDollarChange,
      totalDollarChange: totalDollarChange,
      currency: fAssets[i].currency,
    });
  });
  return assets;
}

const getPrice = (
  price: number,
  currencyFrom: string,
  currencyTo: string,
  fCurrencies: any
) => {
  const from = fCurrencies.find((c: any) => c.code === currencyFrom).rate;
  const to = fCurrencies.find((c: any) => c.code === currencyTo).rate;
  const currencyRate = to / from;
  return price * currencyRate;
};
