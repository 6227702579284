export const setAssetsRef = (ref: any) => {
  return { type: "SET_ASSETS_REF", payload: ref}
};

export const setHistoricalNWsRef = (ref: any) => {
  return { type: "SET_HISTORICALS_REF", payload: ref}
};

export const setCurrenciesRef = (ref: any) => {
  return { type: "SET_CURRENCIES_REF", payload: ref}
};

export const setSettingsRef = (ref: any) => {
  return { type: "SET_SETTINGS_REF", payload: ref}
};