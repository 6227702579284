import {
  PROXY,
  YAHOO_QUOTE_BASE_URL,
  QUOTE_META,
  CHART_META,
} from "../constants/urls";

export const getAsset = async (symbol: string): Promise<any> => {
  try {
    let asset = null;
    await fetch(
      PROXY +
        `${encodeURIComponent(YAHOO_QUOTE_BASE_URL + symbol + QUOTE_META)}`
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then(
        (data: any) => (asset = JSON.parse(data.contents).chart.result[0].meta)
      );
    return asset;
  } catch (e) {
    console.log(e);
  }
};

export const getAssetHistory = async (symbol: string): Promise<any> => {
  try {
    let priceHistory = [null];
    let dateHistory = [null];
    await fetch(
      PROXY +
        `${encodeURIComponent(YAHOO_QUOTE_BASE_URL + symbol + CHART_META)}`
    )
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error("Network response was not ok.");
      })
      .then(
        (data: any) => {
        priceHistory = JSON.parse(data.contents).chart.result[0].indicators.quote[0].close;
        dateHistory = JSON.parse(data.contents).chart.result[0].timestamp;
      });
    return { priceHistory: priceHistory, dateHistory: dateHistory };
  } catch (e) {
    console.log(e);
  }
};
