export const initialState = 0;

export const totalReducer = (state: number = initialState, action: any) => {
  switch (action.type) {
    case "SET_TOTAL": {
      return action.payload;
    }
    default:
      return state;
  }
};
